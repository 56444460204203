import React from "react"

import FooterModule from "../styles/footer.module.scss"

const Footer = () => (
  <footer className={FooterModule.siteFooter} role="contentinfo">
    <div className={FooterModule.panel}>
      <div className="grid-container grid-x grid-padding-x align-center">
        <div className="cell small-12">
          <h2>Let's make something together!</h2>
          <p>
            email{" "}
            <a href="mailto:lance@glance.ca" className={FooterModule.emailNav}>
              lance@glance.ca
            </a>
          </p>

          <ul className={FooterModule.socialNav}>
            <li className={FooterModule.twitter}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/lanceocummings/"
              >
                Twitter
              </a>
            </li>
            <li className={FooterModule.facebook}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/glancedigital/"
              >
                Facebook
              </a>
            </li>
            <li className={FooterModule.linkedin}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/in/lance-cummings-b921714b/"
              >
                Linkedin
              </a>
            </li>
            <li className={FooterModule.instagram}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/lanceocummings/"
              >
                Instagram
              </a>
            </li>
          </ul>

          <p className={FooterModule.copyright}>
            © {new Date().getFullYear()} Glance Digital. All Rights Reserved.&nbsp;{" "}
            <a href="/blog/">Blog</a>&nbsp;&nbsp;
            <a href="/privacy/">Privacy policy</a>
          </p>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer